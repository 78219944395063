import {DesktopUi} from "./desktopUi";
$(document).ready(function() {
        // window.onbeforeunload = function () {
        //         return 'Wollen Sie die Seite wirklich verlassen?';
        // }
        let Gui = new DesktopUi();
        Gui.init();
});



