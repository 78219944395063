import {GeneralUi} from "./generalUi";

export class DesktopUi extends GeneralUi{
    /* INITs */
    init                    = function(){
        let that = this;
        that.columnEventBindings()
        that.accordionEventBindings();
        that.modalEventBindings();
        that.popoverEventBindings('desktop');
        that.initDeepLinks();
        that.initStartToast();
    }

    columnEventBindings     = function(){
        let that = this;
        $('#listView2, #detailView2').on('click', function(e){
            let realizerId = $(this).data('cid');
            let nid = $(this).data('nid');
            that.initalizeSingleNeed(nid,realizerId);
        })
    }

    accordionEventBindings  = function(){
        let that = this;
        //handle big Menu
        $('#accordionMenu .accordion-collapse')
            .on('hidden.bs.collapse shown.bs.collapse',function (e){
                let needBigClass = true;
                that.catMenuAccordion.find('.accordion-item .accordion-collapse').each(function (key,elem){
                    //check if a cat is visible
                    if($(elem).hasClass('show')){
                        needBigClass = false;
                    }
                });
                if(needBigClass){
                    that.catMenuAccordion.addClass('big');
                    $('#button-tag').html('warum gibt es eigentlich kein...?');
                    $('#button-realizer').html('wer sollte das wissen?');
                    $('#button-target').html('wer will es noch?');
                    that.showIndexColums();
                }
                //always remove if a cat is shown:
                if(e.type === 'shown'){
                    that.catMenuAccordion.removeClass('big');
                    $('#button-tag').html('themen');
                    $('#button-realizer').html('realisatoren');
                    $('#button-target').html('nutzer');
                    that.showMainColums();
                }
            })

        //Event for loading Needz List By Type
        $('#menu .accordion-item li').on('click',function (){
            let ctype = $(this).data('ctype');
            let cid = $(this).data('cid');
            let firstNeedtoLoad = $(this).data('firstneed');
            that.handleActiveClass(this);
            that.loadNeedListByType(ctype, cid, firstNeedtoLoad, $('#listView'),function (){
                setTimeout(function (){
                    let li = $('.list-group-item[data-needid="'+ firstNeedtoLoad +'"]');
                    $('#listView').scrollTo(li, 300, {offset:-100});
                    li.addClass('active');
                }, 100);
                that.listEventBindings();
            });
            that.loadNeedDetail(firstNeedtoLoad,$('#detailView'),that.successLoadNeedDetail);
            that.showMainColums();
        });
        $('#accordionMenu .accordion-button').on('click',function (){
            //trigger click on first element of main category
            if($(this).parent().parent().parent().hasClass('big')){
                let list = $('#listView2');
                that.initalizeSingleNeed(list.data('nid'),list.data('cid'));
            }else{
                $(this).parent().parent().find('li').first().trigger('click');
            }

        });
    }

    listEventBindings       = function(){
        let that = this;
        $('.list-sorter').on('click',function (){
            let sortorder = $(this).attr('sort-order');
            let sortType = $(this).attr('sort-type');
            let sortList = $(this).attr('sort-list');
            $(this).attr('sort-order',(sortorder === 'ASC') ? 'DESC' : 'ASC');
            that.listSorter(sortType,sortorder);
            that.handleActiveClass(this);
        });

        //Event for loading Need Destail
        $('#listView .list-group li').on('click',function (){
            let needId = $(this).data('needid');
            that.handleActiveClass(this);
            that.loadNeedDetail(needId,$('#detailView'),that.successLoadNeedDetail);
        });
    }

    initalizeSingleNeed     = function(needId,realizerId){
        let that = this;
        that.catMenuAccordion.removeClass('big');
        $('#collapseRealizer').collapse('show');
        let activeElem = $('#collapseRealizer li[data-cid="'+realizerId+'"]');
        that.handleActiveClass(activeElem);
        that.showMainColums();
        that.loadNeedListByType('realizer', realizerId, needId, $('#listView'),function (){
            setTimeout(function (){
                let li = $('.list-group-item[data-needid="'+ needId +'"]');
                $('#listView').scrollTo(li, 300, {offset:-100});
                li.addClass('active');
            }, 100)
            that.listEventBindings();
        });
        that.loadNeedDetail(needId,$('#detailView'),that.successLoadNeedDetail);
    }

    successLoadNeedDetail   = function (that){
        $("#commentbtn").click(function() {
            $("#detailView").scrollTo($("#comments"),300);
        });
        $('.open-comment-modal').on('click',function (){
            let needId = $(this).data('nid');
            that.displayModal('/comment/create?needId='+needId,function (){
                that.initCreateComment();
            });
        });
    }

    /* BASE */
    showMainColums          = function(){
        $('#detailView, #listView').addClass('active');
        $('#listView2, #detailView2').removeClass('active');
    }
    showIndexColums         = function(){
        $('#listView2, #detailView2').addClass('active');
        $('#listView, #detailView').removeClass('active');
    }
}
