import {list} from "postcss";

// window.cb_hideCookieBanner = hideC;

export class GeneralUi {

    dynamicModal = '#dynamicModal';

    /* UI HELPER */
    catMenuAccordion    = $('#accordionMenu');

    svgMenu = '<g stroke-width="2">\n' +
        '<circle cx="21.5" cy="21.5" r="21.5" stroke="none"/>\n' +
        '<circle cx="21.5" cy="21.5" r="21" fill="none"/>\n' +
        '</g>\n' +
        '<line x2="19" transform="translate(12 13)" fill="none" stroke="#fff" stroke-width="3"/>\n' +
        '<line x2="19" transform="translate(12 21.5)" fill="none" stroke="#fff" stroke-width="3"/>\n' +
        '<line x2="19" transform="translate(12 30)" fill="none" stroke="#fff" stroke-width="3"/>\n';

    svgMenuClose        = '<g stroke-width="2">\n' +
        '<circle cx="21.5" cy="21.5" r="21.5" stroke="none"/>\n' +
        '<circle cx="21.5" cy="21.5" r="21" fill="none"/>\n' +
        '</g>\n' +
        '<g transform="translate(12 13)" stroke-width="3" stroke-linecap="round">\n' +
        '<line x2="18" y2="18" fill="none" stroke="#000"/>\n' +
        '<line x1="18" y2="18" fill="none" stroke="#000"/>\n' +
        '</g>\n';

    isLoggedIn          = function(){ return $('body').hasClass('logged-in')}
    // isMobile            = function(){ return $('body').hasClass('logged-in')}

    handleActiveClass   = function(element){
        let parent = $(element).parent();
        parent.children().each(function (key,elem){
            $(elem).removeClass('active');
        })
        $(element).addClass('active');
    }

    displayModal        = function(url,successCallback){
        let that = this;
        successCallback = successCallback || function (){};
        $(that.dynamicModal+' .modal-content').load(url,function(){
            that.controlDynamicModal('show');
            successCallback(that);
        });
    }

    controlDynamicModal = function(state){
        let that = this;
        $(that.dynamicModal).modal(state);
    }

    modalEventBindings      = function(){
        let that = this;
        let startModal = $('#modalStart');
        if(!that.isLoggedIn()){
            startModal.modal('show');
        }
        startModal.on('click', function(e){
            $(this).modal('hide');
            that.initializeCookieBanner();
        });
        $('.open-create-need-modal').on('click',function (){
            that.displayModal('/need/create',function (){
                that.initCreateNeed();
                $('#create-need-submit').on('click',function (){
                    that.processCreateNeed();
                });
            });
        });
        $('#open-about-modal').on('click',function (){
            that.displayModal('/modal/get?page=about');
        });
        $('#open-privacy-modal').on('click',function (){
            that.displayModal('/modal/get?page=privacy');
        });
        $('#open-imprint-modal').on('click',function (){
            that.displayModal('/modal/get?page=imprint');
        });
        that.userRegModalBinding();
    }

    displayToast        = function(message){
        let toast = $('<div class="toast align-items-center text-white border-1" role="alert" aria-live="assertive" '+
            'aria-atomic="true">'+
            '<div class="d-flex">'+
            '<div class="toast-body">'+
            message+
            '</div>'+
            '<button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>'+
            '</div>'+
            '</div>');
        $('.toast-container').append(toast);
        let toastObj = new bootstrap.Toast($('.toast'), {
            animation: true,
            delay: 5000,
            autohide: true
        });
        toastObj.show();
    }
    /* INITs */
    initCreateNeed      = function(){
        let that = this;
        //init File Upload
        $("#picture-fileupload").fileinput({
            uploadUrl: "/need/process",
            language: "de",
            maxFileCount : 8,
            autoReplace: true,
            showUpload:false,
            showClose: false,
            showCancel: false,
            browseOnZoneClick: true,
            allowedFileTypes: ["image"],
            uploadAsync : false,
            uploadExtraData: function(){
                //let formData =  $('#need-create-form').serializeArray();
                let sendData = {};
                $($('#need-create-form').serializeArray()).each(function (key,field){
                    sendData[field.name] = field.value;
                })
                return sendData;
            }
        }).on('filebatchuploadcomplete', function(event, preview, config, tags, extraData) {
            //console.log('File batch upload complete', preview, config, tags, extraData);
            that.controlDynamicModal('hide');
            that.displayToast('Need angelegt');

        });
        that.addInput();
    }

    initCreateComment   = function(){
        //dom ready for comment create
        let that = this;
        that.addInput();
        $('#create-comment-submit').on('click', function (){
            let formValueArray = $('#comment-create').serializeArray();
            //collect form validation:
            let validation = [];
            //all ratings have values?
            $('#rating_0, #rating_1').each(function (key, elem){
                validation[$(this).attr('id')] = parseInt($(this).val()) > 0 ;
            });
            //do we have text
            validation['text'] = $('#comment-text').val().trim() !== ""

            let commentErrorMessageContainer = $('#comment-create-error-messages');
            commentErrorMessageContainer.empty();
            //     handle Errors
            if(validation['text']=== false){

                commentErrorMessageContainer.append('<span class="block text-alert">Kein Text eingegeben</span>');
            }
            if(validation['rating_0'] === false){
                //no rating_0
                commentErrorMessageContainer.append('<span class="block text-alert">Keine "GUT" Bewertung eingegeben</span>')
            }
            if(validation['rating_1'] === false){
                //no rating_1
                commentErrorMessageContainer.append('<span class="block text-alert">Keine "WICHTIG" Bewertung eingegeben</span>');
            }
            if(commentErrorMessageContainer.children('span').length === 0){
                $.ajax({
                    type: 'POST',
                    url: '/need/comment/process',
                    data: formValueArray
                })
                    .done(function(data) {
                        that.controlDynamicModal('hide');
                    })
                    .fail(function(error) {
                    })
                    .always(function() {
                    });
            }
        });
    }

    initUserProfile     = function(){
        //console.log('USER PROFILE LOADED');
        let that = this;
        let listSelector = $('.profile-need-list');
        let editSelector = $('.edit-need-container');
        $('.open-need-edit').on('click',function (){
            let needId = $(this).data('needid');
            editSelector.load('/need/edit?needId='+needId,function (){
                let form = $("#need-edit-form");
                let formValidator = form.jbvalidator({
                    errorMessage: true,
                    successClass: true,
                });
                editSelector.show();
                listSelector.hide();
                //init File Upload
                $("#picture-fileupload").fileinput({
                    uploadUrl: "/need/editprocess",
                    language: "de",
                    maxFileCount : 8,
                    autoReplace: true,
                    showUpload:false,
                    showClose: false,
                    showCancel: false,
                    browseOnZoneClick: false,
                    overwriteInitial: false,
                    initialPreviewDelimiter: "<br>",
                    initialPreview: $('#currentPictures').html().trim() === '' ? false : $('#currentPictures').html(),
                    allowedFileTypes: ["image"],
                    uploadAsync : false,
                    uploadExtraData: function(){
                        //COLLET COMPLETE FORMDATA TO SEND ALONG
                        let sendData = {};
                        $(form.serializeArray()).each(function (key,field){
                            sendData[field.name] = field.value;
                        })
                        return sendData;
                    }
                }).on('filebatchuploadcomplete', function(event, preview, config, tags, extraData) {
                    //SUCCESS AJAX REQUEST (.done FUNCTION)
                    //console.log('File batch upload complete', preview, config, tags, extraData);
                    editSelector.hide();
                    listSelector.show();
                    that.displayToast('Need gespeichert');

                }).on('fileclear',function(event){
                    console.log('FILE-CLEAR');
                });
                //eventbinding for descriptions
                that.addInput();
                //Switch toggle list and edit container
                $('#edit-need-submit').on('click',function (){
                    if(formValidator.checkAll() === 0){
                        $("#picture-fileupload").fileinput('upload');
                    }
                });
                $('#edit-need-cancel').on('click',function(){
                    editSelector.hide();
                    listSelector.show();
                });
                //Delete Button in Preview
                $('.kv-file-remove').on('click', function(event) {
                    event.preventDefault();
                    let previewImg = $(this).parent().parent().parent().parent();
                    let picId = previewImg.find('img').first().data('id');
                    previewImg.remove();
                    $('#need-edit-form').append('<input type="hidden" name="pic-remove['+picId+']" value="'+picId+'" />');
                    //@todo adjust plugin, trigger delete events for previewFile
                    //$("#picture-fileupload").fileinput('reset');
                });
            });
        });
    }

    initUserRegistration= function (){
        let that = this;
        let form = $('#user-registration-form');
        form.trigger("reset");
        let formValidator = form.jbvalidator({
            errorMessage: true,
            successClass: true,
        });
        form.on('submit',function (e){
            e.preventDefault();
            if(this.checkValidity()){
                $("#user-registration-submit").attr('disabled',true).html(
                    '<span class="text-success">Bitte warten... kann etwas dauern...</span>');
                $.ajax(
                    '/user/regprocess',{
                        type: 'POST',
                        data: form.serializeArray()
                    }
                ).done(function (data){
                    that.controlDynamicModal('hide');
                    that.displayToast('Erfolgreich registriert, bitte überprüfen Sie ihr Email Postfach');
                }).fail(function (xhr){
                    let data = xhr.responseJSON;
                    let errorContainer = $('#user-registration-form .user-registration-errors');
                    errorContainer.empty();
                    errorContainer.append('<p class="text-alert">'+data.reason+'</p>');
                    $("#user-registration-submit").attr('disabled',false);
                });
            }
        });
    }

    initStartToast      = function(){
        let that = this;
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if(params.toast !== undefined){
            switch (params.toast){
                case 'logout':
                    that.displayToast('Erfolgreich ausgeloggt')
                    break;
                case 'login':
                    that.displayToast('Erfolgreich eingeloggt')
                    break;
                case 'not-allowed':
                    that.controlDynamicModal('hide');
                    that.displayToast('Aktion nicht erlaubt');
                    break;
                case '404':
                    that.displayToast('Nicht gefunden');
                    break;
                default:
                    that.displayToast(params.toast);
                    break;
            }
            //remove toast querystring if possible:
            if(window.history !== undefined && window.history.pushState !== undefined) {
                window.history.pushState({}, document.title, window.location.pathname);
            }
        }
    }

    initDeepLinks           = function(){
        //deeplink to a specific need Notation: #NEEDID-REALIZERID:
        let that = this;
        if(window.location.hash){
            let hash = window.location.hash.substr(1);
            hash = hash.split('-');
            console.log(hash);
            let needId = hash[0];
            let realizerId = hash[1];
            that.initalizeSingleNeed(needId,realizerId);
        }
    }

    /* PROCESS */
    processCreateNeed   = function(){
        let form = $("#need-create-form");
        let formValidator = form.jbvalidator({
            errorMessage: true,
            successClass: true,
        });
        if(formValidator.checkAll() === 0){
            $("#picture-fileupload").fileinput('upload');
        }
    }

    /* BASE FUNCTIONS */
    loadNeedDetail      = function(needId,insertSelector,successCallback,errorCallback,alwaysCallback) {
        let that = this;
        successCallback = successCallback || function (){};
        errorCallback = errorCallback || function (){};
        alwaysCallback = alwaysCallback || function (){};
        insertSelector = insertSelector || $('#detailView');
        $.ajax( "/need/detail?needId="+needId )
            .done(function(html) {
                insertSelector.html(html);
                successCallback(that);
            })
            .fail(function(error) {
                errorCallback();
            })
            .always(function() {
                alwaysCallback();
            });
    }

    loadNeedListByType  = function(ctype, cid, nid, insertSelector, successCallback,errorCallback,alwaysCallback) {
        successCallback = successCallback || function (){};
        errorCallback = errorCallback || function (){};
        alwaysCallback = alwaysCallback || function (){};
        insertSelector = insertSelector || $('#listView');
        $.ajax( "/need/list?type="+ctype+"&id="+cid )
            .done(function(html) {
                //fill html template from xhr to list col
                insertSelector.html(html);
                successCallback();
            })
            .fail(function(error) {
                insertSelector.html('<p class="text-alert">Nothing found...</p>')
                errorCallback();
            })
            .always(function() {
                alwaysCallback();
            });
    }

    popoverEventBindings    = function(cclass){
        let that = this;
        let title = 'ANMELDEN';
        let clickTarget = $('#open-profile-modal');
        let content = '<div class="login-form">'+
            '<form action="/user/login" method="POST" class="row" id="user-login-form" novalidate="novalidate">'+
            '<div class="mb-3">'+
            '<label for="login-username" class="form-label">Email</label>'+
            '<input type="text" id="login-username" name="email" class="form-control" placeholder="Username" required>'+
            '</div>'+
            '<div class="mb-3">'+
            '<label for="login-password" class="form-label">Password</label>'+
            '<input type="password" id="login-password" name="password" class="form-control" placeholder="Password" required>'+
            '<div class ="user-login-errors"></div>'+
            '</div>'+
            '<div class="mb-3">'+
            '<button type="button" id="user-login-submit" class="btn btn-outline-info float-end">Login</button>'+
            '</div>'+
            '<div class="mb-3">'+

            '<button type="button" class="btn btn-outline-info open-user-registration-modal float-end">Registrieren</button>'+
            '<span class="alert-info">Neu hier? Dann bitte Registrieren</span>'+
            '</div>'+
            '</form>'+
            '</div>';

        if(that.isLoggedIn()){
            let username = clickTarget.data('auth-username');
            let useremail = clickTarget.data('auth-email');
            content = '<div class="account-popover">'+
                '<div class="mb-3">'+
                '<p class="text-sm-center">Hallo '+username+'!</p>'+
                '<p class="text-sm-center">('+useremail+')</p>'+
                '</div>'+
                '<div class="mb-3">'+
                '<a class="btn btn-outline-info" id="user-self">Profil</a>'+
                '<a href="/user/logout" type="button" class="btn btn-outline-info float-end">Ausloggen</a>'+
                '</div>'+
                '</div>';
            title = 'EINLOGGEN';
            clickTarget.on('shown.bs.popover',function(){
                $("#user-self").off().on("click",function (){
                    $("#dynamicModal .modal-content").load('/user/self',function(){
                        $("#dynamicModal").modal("show");
                        userPopover.hide();
                        that.initUserProfile();
                    })
                });
            });
        }else{
            clickTarget.on('shown.bs.popover',function() {
                that.userRegModalBinding(userPopover);
                let form = $('#user-login-form');
                let formValidator = form.jbvalidator({
                    errorMessage: true,
                    successClass: true,
                });
                $('#user-login-submit').on('click',function (e){
                    e.preventDefault();
                    if(formValidator.checkAll() === 0){
                        $.ajax(
                            '/user/login',{
                                type: 'POST',
                                data: form.serializeArray()
                            }
                        ).done(function (data){
                            window.location.href = '/';

                        }).fail(function (xhr){
                            let data = xhr.responseJSON;
                            let errorContainer = $('#user-login-form .user-login-errors');
                            errorContainer.empty();
                            errorContainer.append('<p class="text-alert">'+data.reason+'</p>');
                            $('#login-username, #login-password').val("").addClass('is-invalid');
                        });
                    }
                });
            });
        }

        let userPopover = new bootstrap.Popover(clickTarget, {
            container: 'body',
            sanitize: false,
            placement: 'bottom',
            title: title,
            html: true,
            content: content,
            customClass: cclass,
        });

        let menuClickEinloggen = $('.open-login-popover');
        menuClickEinloggen.click(function () {
            userPopover.show();
        });
    }


    userRegModalBinding     = function (userPopover){
        userPopover = userPopover || false;
        let that = this;
        $('.open-user-registration-modal').off().on('click',function (){
            if(userPopover){
                userPopover.hide();
            }
            that.displayModal('/user/register',function (){
                that.initUserRegistration();
            });
        });
    }

    listSorter = function(sortType, sortOrder, list) {
        var i, switching, b, shouldSwitch;
        var list = list || $('#listView .list-group');
        switching = true;
        /* Make a loop that will continue until
        no switching has been done: */
        while (switching) {
            switching = false;
            b = list.find("li");
            // Loop through all list items:
            for (i = 0; i < (b.length - 1); i++) {
                // Start by saying there should be no switching:
                shouldSwitch = false;
                /* Check if the next item should
                switch place with the current item: */
                if(sortType === 'ABC'){
                    let condition = false;
                    if(sortOrder === 'ASC'){
                        //condition = (b[i].getElementsByClassName('card-title')[0].innerHTML.toLowerCase() > b[i + 1].getElementsByTagName('h5')[0].innerHTML.toLowerCase());
                        condition = ($(b[i]).find('.card-title')[0].innerHTML.toLowerCase() > $(b[i + 1]).find('.card-title')[0].innerHTML.toLowerCase());
                    }else{
                        condition = ($(b[i]).find('.card-title')[0].innerHTML.toLowerCase() < $(b[i + 1]).find('.card-title')[0].innerHTML.toLowerCase());
                    }
                    if (condition) {
                        /* If next item is alphabetically lower than current item,
                        mark as a switch and break the loop: */
                        shouldSwitch = true;
                        break;
                    }
                }
                if(sortType === 'DATE'){
                    let condition = false;
                    if(sortOrder === 'ASC'){
                        condition = ($(b[i]).find('.need-date').attr('data-date') > $(b[i + 1]).find('.need-date').attr('data-date'));
                    }else{
                        condition = ($(b[i]).find('.need-date').attr('data-date') < $(b[i + 1]).find('.need-date').attr('data-date'));
                    }
                    if (condition) {
                        shouldSwitch = true;
                        break;
                    }
                }
                if(sortType === 'GOOD'){
                    let condition = false;
                    if(sortOrder === 'ASC'){
                        condition = ($(b[i]).find('.rating').attr('data-good') > $(b[i + 1]).find('.rating').attr('data-good'));
                    }else{
                        condition = ($(b[i]).find('.rating').attr('data-good') < $(b[i + 1]).find('.rating').attr('data-good'));
                    }
                    if (condition) {
                        shouldSwitch = true;
                        break;
                    }
                }
                if(sortType === 'IMPORTANT'){
                    let condition = false;
                    if(sortOrder === 'ASC'){
                        condition = ($(b[i]).find('.rating').attr('data-important') > $(b[i + 1]).find('.rating').attr('data-important'));
                    }else{
                        condition = ($(b[i]).find('.rating').attr('data-important') < $(b[i + 1]).find('.rating').attr('data-important'));
                    }
                    if (condition) {
                        shouldSwitch = true;
                        break;
                    }
                }
            }
            if (shouldSwitch) {
                /* If a switch has been marked, make the switch
                and mark the switch as done: */
                b[i].parentNode.insertBefore(b[i + 1], b[i]);
                switching = true;
            }
        }
        $("#listView").scrollTo($("#list-top"),300);
    }

    addInput            = function(){
        $('.descriptions a').on('click',function (e){
            let inputSelector = $(this).parent().parent().find('.input-group:first');
            let newInput = inputSelector.clone(true);
            let oldName = $(this).parent().parent().find('.input-group:last').find('input').attr('name');
            let oldNummer = parseInt(oldName.match(/[0-9]/g));
            newInput.find('input').val('');
            newInput.find('input').attr('name',oldName.replace(oldNummer,oldNummer+1));
            newInput.appendTo($(this).parent().parent()).append(
                '<div class="input-group-append"><a class="btn btn-outline-info btn-sm remove-description"><i class="bi bi-trash"></i></a></div>'
            )
            $('.remove-description').off().on('click',function(){
                $(this).parent().parent().remove();
            });
        });
    }

    showCookieBanner = function(){
        let cookieBanner = document.getElementById("cb-cookie-banner");
        cookieBanner.style.display = "block";
    }

    hideCookieBanner = function(){
        localStorage.setItem("cb_isCookieAccepted", "yes");

        let cookieBanner = document.getElementById("cb-cookie-banner");
        cookieBanner.style.display = "none";
    }

    initializeCookieBanner = function(){
        let that = this;
        let isCookieAccepted = localStorage.getItem("cb_isCookieAccepted")||'no';

        $('#cookie-accept').on('click', function (){
            that.hideCookieBanner();
        });
        if(isCookieAccepted === "no")
        {
            localStorage.setItem("cb_isCookieAccepted", "no");
            that.showCookieBanner();
        }
        if(isCookieAccepted === "no"){
            that.showCookieBanner();
        }
    }

}
